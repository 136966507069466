// 数字 每三位数加一个逗号 1,000.00
export const number_format = (val) => {
  if (val == "-") {
    return "-";
  }
  if (val === "0" || val === 0) {
    return "0";
  }
  if (!val) {
    return "";
  }
  let num = val + "";
  if (num.indexOf(".") != -1) {
    let num1 = num.split(".")[0]; //整数
    let num2 = num.split(".")[1]; //小数
    let num3 = num1.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,");
    if (num2.length == 1) {
      return num3 + "." + num2 + "0";
    } else {
      return num3 + "." + num2;
    }
  } else {
    return num.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") + ".00";
  }
};

// 数字 每三位数加一个逗号 1,000
export const number_format2 = (val) => {
  if (val == "-") {
    return "-";
  }
  if (val === "0" || val === 0) {
    return "0";
  }
  if (!val) {
    return "";
  }
  let num = val + "";
  if (num.indexOf(".") != -1) {
    let num1 = num.split(".")[0]; //整数
    let num2 = num.split(".")[1]; //小数
    let num3 = num1.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,");
    if (num2.length == 1) {
      return num3 + "." + num2 + "0";
    } else {
      return num3 + "." + num2;
    }
  } else {
    return num.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,");
  }
};
