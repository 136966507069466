<template>
  <div id="app" v-loading="$store.state.api_loading" element-loading-background="rgba(0, 0, 0, 0.6)">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body, html {
  padding: 0;
  margin: 0;
}

.pagination_box {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
}
</style>
