import Vue from "vue";
import App from "./App.vue";
import Vuex from "vuex";
import router from "./router";
import store from './store'
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
Vue.use(Vuex);

// 使用时间转换插件 moment.js
import moment from 'moment'
// 全局  overall
function custom_moment(v) {
    return moment(v).format('YYYY/MM/DD HH:mm');
}
// 时间转换
Vue.prototype.overall_time = custom_moment;

// 数字 1,000.00
import {number_format,number_format2} from "./utils/number_format";

Vue.prototype.number_format = number_format;
// 数字 1,000
Vue.prototype.number_format2 = number_format2;

Vue.config.productionTip = false;

// 版本号的判断逻辑，版本号不一致就重新加载
const VUE_APP_VERSION = require('../package.json').version;
const vers = window.localStorage.getItem("Ethelix_admin_Version");
if (VUE_APP_VERSION != vers) {
  localStorage.clear();
  sessionStorage.clear();
  window.localStorage.setItem("Ethelix_admin_Version", VUE_APP_VERSION);
  location.reload();
}

new Vue({
    router,
	store,
    render: (h) => h(App),
}).$mount("#app");
