import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/login.vue"),
    },
    {
        path: "/",
        name: "layout",
        redirect: "/dashbord",
        component: () => import("@/layout/layout.vue"),
        children: [
            {
                path: "/dashbord", //控制面板
                name: "dashbord",
                component: () => import("@/views/dashbord.vue"),
            },
            {
                path: "/walletRecord", //出售Hicoin
                name: "walletRecord",
                component: () => import("@/views/walletRecord.vue"),
            },
            {
                path: "/sellHicoin", //出售Hicoin
                name: "sellHicoin",
                component: () => import("@/views/sellHicoin.vue"),
            },
            {
                path: "/buyHicon", //收购Hicoin
                name: "buyHicon",
                component: () => import("@/views/buyHicon.vue"),
            },
            {
                path: "/payeeEdit", //收款设置
                name: "payeeEdit",
                component: () => import("@/views/payeeEdit.vue"),
            },
            {
                path: "/setting", //收款设置
                name: "setting",
                component: () => import("@/views/setting.vue"),
            },
            {
                path: "/notice", //修改密码
                name: "notice",
                component: () => import("@/views/noticeSetting.vue"),
            },
            {
                path: "/modifyPassword", //修改密码
                name: "modifyPassword",
                component: () => import("@/views/modifyPassword.vue"),
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    linkActiveClass: "active",
    base: process.env.BASE_URL,
    scrollBehavior: () => ({
        y: 0,
    }),
    routes,
});

router.beforeEach();

export default router;
